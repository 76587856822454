var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"event",attrs:{"fluid":""}},[_c('b-container',{staticClass:"banner"},[_c('img',{staticClass:"banner-img",attrs:{"src":require("@/assets/images/event/baner.png"),"alt":""}}),_c('div',{staticClass:"search-bar"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('events')))])])]),(_vm.ListEvent && false)?_c('b-container',[_c('b-row',[_vm._l((_vm.ListEvent.data),function(event){return _c('b-col',{key:("event-" + (event.event_id)),attrs:{"md":"6","lg":"4","cols":"12"}},[_c('div',{staticClass:"item-carousel"},[_c('router-link',{attrs:{"to":{
                            name: 'EventDetail',
                            params: {
                                slug: event.event_slug,
                                type: 'su-kien',
                            },
                        }}},[_c('div',{staticClass:"event-item"},[_c('div',{staticClass:"event-img"},[_c('img',{attrs:{"src":event.event_image,"alt":""}})]),_c('div',{staticClass:"event-content"},[_c('h4',[_vm._v(_vm._s(event.event_title))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('events'))+" - "+_vm._s(_vm.getDateTime(event.event_time_start))+" ")])]),_c('div',{staticClass:"line-bottom"}),_c('div',{staticClass:"box-sub-bg"})])]),_c('div',{staticClass:"event-text"},[(event.event_description)?_c('p',[_vm._v(" "+_vm._s(event.event_description)+" ")]):_vm._e(),_c('router-link',{attrs:{"to":{
                                name: 'EventDetail',
                                params: {
                                    slug: event.event_slug,
                                    type: 'su-kien',
                                },
                            }}},[_vm._v(" "+_vm._s(_vm.$t('seeMore'))+" ")])],1)],1)])}),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pagination"},_vm._l((_vm.listPage),function(indexPage){return _c('div',{key:indexPage,staticClass:"dot",class:_vm.page == indexPage ? 'dot-active' : '',on:{"click":function($event){_vm.page = indexPage}}})}),0)])],2)],1):_c('div',{staticClass:"container"},[_c('div',{staticClass:"no-project"},[_vm._v("Tạm thời chưa có sự kiện")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }